import { Box, Typography } from "@mui/material";
import type { FC } from "react";
import { Page } from "../../components/Page";
import { ActiveOrganization } from "./ActiveOrganization";
import { PlanSwitcher } from "./PlanSwitcher";

export const Organization: FC = () => {
  return (
    <Page title="Organization">
      <Box my={4}>
        <Typography variant="h2">Active Organization</Typography>
        <ActiveOrganization />
      </Box>
      {/* <Box my={4}>
        <OrganizationForm organization={organizationQuery.data!.organization} />
      </Box> */}
      <Box my={4}>
        <Box display="flex" flexDirection="column" gap={4}>
          <Typography variant="h2">Plan</Typography>
          <PlanSwitcher />
        </Box>
      </Box>
    </Page>
  );
};
