import { object, ObjectSchema, string } from "yup";
import type { IdOnly, Organization, User } from "..";

export type Webhooks = {
  onTrackingEvent: string | null;
  onRequestAddress: string | null;
  onRequestRedirect: string | null;
};

const webhookSchema: ObjectSchema<Partial<Webhooks>> = object({
  onTrackingEvent: string().required().nullable().url(),
  onRequestAddress: string().required().nullable().url(),
  onRequestRedirect: string().required().nullable().url(),
}).partial();

export type BasicIntegration = {
  name: string;
  id: string;
  organization: IdOnly | Organization;
  createdBy: IdOnly | User;
  // webhooks: Webhooks;
};

export type Integration = BasicIntegration;

export type ListIntegrationsResponse = {
  integrations: Integration[];
};

export type UpdateIntegrationRequest = {
  webhooks: Partial<Webhooks>;
};

export const updateIntegrationSchema: ObjectSchema<UpdateIntegrationRequest> =
  object({
    webhooks: webhookSchema,
  });

export type UpdateIntegrationResponse = { integration: Integration };

export type LinkKnockrRequest = {
  stopId: string;
  knockr: string;
  code: string;
};
