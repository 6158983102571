import type { RawTimeZone } from "@vvo/tzdb";
import { rawTimeZones } from "@vvo/tzdb";

export function deviceTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

// Convert a RawTimeZone object to "Canada - Vancouver (Pacific Time)"
export const friendlyName = (tz: RawTimeZone) =>
  `${tz.countryName} - ${tz.mainCities[0]} (${tz.alternativeName})`;

// A list of timezone names *in order of their friendly name*
export const sortedTimezones = rawTimeZones
  .sort((a, b) => friendlyName(a).localeCompare(friendlyName(b)))
  .map((tz) => tz.name);

// Convert "America/Vancouver" to a RawTimeZone object
// Have to inspect inside the group because timezones like America/Indiana/Indianapolis aren't "top level"
// but are contained inside others according to tzdb
export function getRawTimeZone(tzName: string) {
  const raw = rawTimeZones.find((tzGroup) =>
    tzGroup.group.find((tz) => tz === tzName)
  );
  if (!raw) {
    throw `Unable to get time zone for ${tzName}`;
  }
  return raw;
}

// Convert "America/Vancouver" to "Canada - Vancouver (Pacific Time)"
export const friendlyTzName = (tzName: string) =>
  friendlyName(getRawTimeZone(tzName));
