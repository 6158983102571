import type { FC, ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Layout } from "../components/Layout";
import { useAuth } from "../hooks/useAuth";
import { Onboarding } from "../pages/onboarding/Onboarding";

export const PrivateRoute: FC<{ layout?: boolean; element: ReactElement }> = ({
  layout = true,
  element,
}) => {
  const location = useLocation();
  const { currentUser, organizationSelectionRequired } = useAuth();

  // If we don't have a current user at all, redirect to signin
  if (!currentUser) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  // If we don't have any organizations, redirect to onboarding
  if (currentUser.organizations.length === 0) {
    return <Onboarding />;
  }

  // If we have an active organization we're good to go into the app
  if (currentUser.hasActiveOrganization()) {
    console.log("currentUser.hasActiveOrganization()", "return element");
    return layout ? <Layout>{element}</Layout> : element;
  }

  // If we have a user but they should be selecting an organization, redirect to sign in
  // The sign in screen prompts for organization selection
  if (currentUser && organizationSelectionRequired) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return <Navigate to="/" state={{ from: location }} />;
};
