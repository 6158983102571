import { Auth } from "aws-amplify";
import type { AxiosRequestConfig } from "axios";
import axios from "axios";
import config from "../config";
import { ACTIVE_ORGANIZATION_KEY } from "../hooks/useAuth";
import { deviceTimezone } from "../shared/frontend";
import { getUserLocalStorage } from "./user_local_storage";

// Wrappers for Amplify API calls
// - Use template-typed request/response
// - Add Cognito headers for use in local env
// - Stops us from having to put `config.api.NAME` everywhere
// - Includes the Cognito JWT for API access

// ID token can be used on endpoints behind a Cognito User Pool Authorizer
export async function idToken() {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
}

// Access token can be used for AWS resources and/or if we had an endpoint
// behind an OAuth scope, defined in Api Gateway
// async function accessToken() {
//   return (await Auth.currentSession()).getAccessToken().getJwtToken();
// }

async function axiosConfig(options?: {
  async?: boolean;
}): Promise<AxiosRequestConfig> {
  const headers: Record<string, string> = {};

  headers["X-Time-Zone"] = deviceTimezone();

  try {
    headers["Authorization"] = await idToken();

    const activeOrganizationId = await getUserLocalStorage<string>(
      ACTIVE_ORGANIZATION_KEY
    );

    if (activeOrganizationId) {
      headers["X-Organization"] = activeOrganizationId;
    }

    if (options?.async) {
      headers["Prefer"] = "respond-async";
    }
  } catch (error) {
    return {
      headers: undefined,
    };
  }

  return {
    headers,
  };
}

export const get = async <ResponseType = any>(url: string) => {
  const { data } = await axios.get<ResponseType>(
    `${config.api.URL}${url}`,
    await axiosConfig()
  );
  return data;
};

export const post = async <ResponseType = any, RequestType = any>(
  url: string,
  payload: RequestType,
  options?: { async?: boolean }
) => {
  const { data } = await axios.post<ResponseType>(
    `${config.api.URL}${url}`,
    payload,
    await axiosConfig({ async: options?.async })
  );
  return data;
};

export const put = async <ResponseType = any, RequestType = any>(
  url: string,
  payload: RequestType
) => {
  const { data } = await axios.put<ResponseType>(
    `${config.api.URL}${url}`,
    payload,
    await axiosConfig()
  );
  return data;
};

export const del = async <ResponseType = any, RequestType = any>(
  url: string,
  payload?: RequestType
) => {
  const { data } = await axios.delete<ResponseType>(`${config.api.URL}${url}`, {
    data: payload,
    ...(await axiosConfig()),
  });
  return data;
};
