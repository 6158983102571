import { Settings } from "luxon";

// https://github.com/DefinitelyTyped/DefinitelyTyped/pull/64995

Settings.throwOnInvalid = true;

declare module "luxon" {
  interface TSSettings {
    throwOnInvalid: true;
  }
}
