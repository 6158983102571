import { DateTime } from "luxon";
import type {
  Appointment,
  Contact,
  Customer,
  Location,
  ScheduledAppointment,
} from "../../shared/api_schema";
import { toLocale } from "../../shared/frontend";

export enum StoplikeType {
  APPOINTMENT,
  SCHEDULED_APPOINTMENT,
}

export type Stoplike = {
  active: boolean;
  type: StoplikeType;
  id: string;
  location: Location;
  contact: Contact;
  scheduledAppointment?: ScheduledAppointment;
  appointment?: Appointment;
  localOpen: string;
  localClose: string;
  service: number;
};

export const stoplikeTypeString = (s: Stoplike): string => {
  switch (s.type) {
    case StoplikeType.SCHEDULED_APPOINTMENT:
      return "Scheduled";
    case StoplikeType.APPOINTMENT:
      return "Appointment";
  }
};

// Sort by earliest open time first, then earliest close time, then location name
export function sortStopLikes(
  stoplikes: Stoplike[],
  skippedStopLikes: Stoplike[]
) {
  stoplikes.sort(
    (a: Stoplike, b: Stoplike) =>
      a.localOpen.localeCompare(b.localOpen) ||
      a.localClose.localeCompare(b.localClose) ||
      a.location.name.localeCompare(b.location.name)
  );

  stoplikes.sort((a, b) => {
    if (skippedStopLikes.includes(a) && !skippedStopLikes.includes(b)) {
      return 1;
    } else if (!skippedStopLikes.includes(a) && skippedStopLikes.includes(b)) {
      return -1;
    } else {
      return 0;
    }
  });

  return stoplikes;
}

export function scheduledStoplike(scheduled: ScheduledAppointment): Stoplike {
  return {
    active: true,
    type: StoplikeType.SCHEDULED_APPOINTMENT,
    id: scheduled.id,
    scheduledAppointment: scheduled,
    location: (scheduled.customer as Customer).location,
    contact: (scheduled.customer as Customer).contact,
    localOpen: scheduled.open,
    localClose: scheduled.close,
    service: scheduled.service,
  };
}

export function appointmentStoplike(appointment: Appointment): Stoplike {
  return {
    active: true,
    type: StoplikeType.APPOINTMENT,
    id: appointment.id,
    appointment: appointment,
    location: appointment.location,
    contact: appointment.contact,
    localOpen: toLocale(
      appointment.open,
      appointment.location.timeZone,
      DateTime.TIME_24_SIMPLE
    ),
    localClose: toLocale(
      appointment.close,
      appointment.location.timeZone,
      DateTime.TIME_24_SIMPLE
    ),
    service: appointment.service.expected!,
  };
}
