import { MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";
import type { FC } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { RButton } from "../../components/RButton";
import { RFormButtons } from "../../components/RFormButtons";
import { FormSelect } from "../../components/forms/FormSelect";
import { FormTextField } from "../../components/forms/FormTextField";
import { useUpdateUserMutation } from "../../hooks/mutations/useUpdateUserMutation";
import { useAuth } from "../../hooks/useAuth";
import { useSnackbar } from "../../hooks/useSnackbar";
import { del, post } from "../../lib/amplify";
import type { CreateUserRequest, UserWithRole } from "../../shared/api_schema";
import { UserRole, compareRole } from "../../shared/api_schema";
import { EMAIL_REGEX, userRoleString } from "../../shared/frontend";
import { availableRoles } from "./Users";

// These should match what the UI form component uses for its state
type UserFormTypes = {
  firstName: string;
  lastName: string;
  email: string;
  role: (typeof UserRole)[keyof typeof UserRole];
};

export const UserForm: FC<{
  user?: UserWithRole;
  onSubmitSuccess: () => void;
}> = ({ user, onSubmitSuccess }) => {
  const { currentUser } = useAuth();

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm<UserFormTypes>({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      role: user?.role ?? currentUser!.activeOrganization.role,
    },
  });

  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  const addUserMutation = useMutation(
    async (payload: CreateUserRequest) => post("/users", payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        snackbar.show("User added!");
        onSubmitSuccess?.();
      },
      onError: (err: any) => {
        if (err.response) {
          setError("email", {
            type: "exists",
            message: err.response.data.message,
          });
        }
      },
    }
  );

  const updateUserMutation = useUpdateUserMutation();

  const removeUserMutation = useMutation(
    async (userId: string) => del(`/users/${userId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        snackbar.show("User has been removed");
        onSubmitSuccess?.();
      },
    }
  );

  const formSubmit = (data: UserFormTypes) => {
    if (user) {
      updateUserMutation.mutate(
        {
          id: user.id,
          ...data,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("users");
            snackbar.show("User updated!");
            onSubmitSuccess?.();
          },
        }
      );
    } else {
      addUserMutation.mutate(data);
    }
  };

  // Disable editing if you're editing an existing user with greater role
  const editingDisabled =
    user && compareRole(currentUser!.activeOrganization.role, user.role) < 0;

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(formSubmit)}
      style={{ margin: 2 }} // Required to eliminate scrollbar
    >
      <Grid container spacing={5}>
        <Grid item md={4}>
          <FormTextField
            control={control}
            errors={errors}
            name="firstName"
            label="First Name"
            rules={{ required: "A first name is required" }}
            autoFocus
            disabled={editingDisabled}
          />
        </Grid>

        <Grid item md={4}>
          <FormTextField
            control={control}
            errors={errors}
            name="lastName"
            label="Last Name"
            rules={{ required: "A last name is required" }}
            disabled={editingDisabled}
          />
        </Grid>

        <Grid item md={4}>
          {/* Show all available roles if it's a new user or we can edit */}
          {/* Otherwise show a pre-filled uneditable thing */}
          {!user || !editingDisabled ? (
            <FormSelect
              control={control}
              errors={errors}
              name="role"
              label="Role"
              rules={{ required: "A role is required" }}
            >
              {availableRoles(currentUser!.activeOrganization.role).map((r) => (
                <MenuItem key={r} value={r}>
                  {userRoleString(r)}
                </MenuItem>
              ))}
            </FormSelect>
          ) : (
            <FormSelect
              control={control}
              errors={errors}
              name="role"
              label="Role"
              rules={{ required: "A role is required" }}
              disabled
            >
              {Object.values(UserRole).map((r) => (
                <MenuItem key={r} value={r}>
                  {userRoleString(r)}
                </MenuItem>
              ))}
            </FormSelect>
          )}
        </Grid>

        <Grid item md={6}>
          <FormTextField
            control={control}
            errors={errors}
            name="email"
            label="Email"
            rules={{
              required: "An email address is required",
              pattern: {
                value: EMAIL_REGEX,
                message: "The email address is invalid",
              },
            }}
            disabled={!!user} // Can't change an email (it won't align with Cognito anymore)
          />
        </Grid>

        <Grid item md={12}>
          <RFormButtons>
            {user && (
              <RButton
                color="secondary"
                onClick={async () => removeUserMutation.mutateAsync(user.id)}
                loading={removeUserMutation.isLoading}
              >
                Remove from organization
              </RButton>
            )}
            <RButton
              loading={
                addUserMutation.isLoading || updateUserMutation.isLoading
              }
              type="submit"
            >
              Save
            </RButton>
          </RFormButtons>
        </Grid>
      </Grid>
    </form>
  );
};
