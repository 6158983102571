import AddCircle from "@mui/icons-material/AddCircle";
import Search from "@mui/icons-material/Search";
import {
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { RButton } from "../../components/RButton";
import { FormSelect } from "../../components/forms/FormSelect";
import { FormTextField } from "../../components/forms/FormTextField";
import useRemoveStopShipmentMutation from "../../hooks/mutations/useRemoveStopShipmentMutation";
import { useSnackbar } from "../../hooks/useSnackbar";
import { get, post } from "../../lib/amplify";
import { isStopDeparted } from "../../lib/map_helpers";
import {
  DeepStop,
  RequirementType,
  Shipment,
  ShipmentType,
  ShowShipmentResponse,
  UpsertShipmentRequest,
  UpsertShipmentResponse,
} from "../../shared/api_schema";
import { ListTagsResponse } from "../../shared/api_schema/tags/tags";
import { requirementTypeString } from "./RequirementCard";

type SearchForm = {
  code: string;
};

type Props = {
  stop: DeepStop;
  onShipmentAdded?: (shipmentId: string) => void;
  onShipmentRemoved?: (shipmentId: string) => void;
};

function shipmentTypeString(type?: ShipmentType) {
  switch (type) {
    case ShipmentType.DELIVERY:
      return "Delivery";
    case ShipmentType.PICKUP:
      return "Pickup";
    default:
      return "";
  }
}

// Can be used in two situations:
// 1. When creating a new Appointment (in which case there's no Stop yet, and thus no existing linked shipments)
// 2. When altering an existing Stop (in which case there's a Stop and maybe existing linked shipments)
export const StopShipments: FC<Props> = ({
  stop,
  onShipmentAdded,
  onShipmentRemoved,
}) => {
  const searchForm = useForm<SearchForm>({ defaultValues: { code: "" } });

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
  } = useForm<UpsertShipmentRequest>({
    defaultValues: {
      code: "",
      stop: { type: ShipmentType.DELIVERY },
      requirements: [RequirementType.SCAN],
      tags: [],
    },
  });

  // undefined indicates a search hasn't occurred yet
  // null indicates a search occurred but turned up no results
  const [searching, setSearching] = useState(false);
  const [searchResult, setSearchResult] = useState<Shipment | null>();

  const createableRequirementTypes = Object.values(RequirementType).filter(
    (t) => t !== RequirementType.DRIVER_CODE
  );

  async function search({ code }: SearchForm) {
    setSearching(true);
    setSearchResult(undefined);
    setValue("code", code.toUpperCase());

    try {
      const { shipment } = await get<ShowShipmentResponse>(
        `/shipments/code/${code.toUpperCase()}`
      );
      setSearchResult(shipment);
    } catch {
      setSearchResult(null);
    }

    setSearching(false);
  }

  const snackbar = useSnackbar();

  const createStopShipmentQuery = useMutation(
    async (data: UpsertShipmentRequest) =>
      post<UpsertShipmentResponse>("/shipments", data),
    {
      onError: (err: any) => {
        snackbar.show(err.response.data.message, "error");
      },
      onSuccess: (response) => {
        onShipmentAdded?.(response.shipment.id);
      },
    }
  );

  const removeRouteStopMutation = useRemoveStopShipmentMutation();

  // Use our Stop prop to assign to the request, so we link the Shipment to this Stop
  async function createStopShipment(data: UpsertShipmentRequest) {
    data.stop = { id: stop.id, type: data.stop!.type };

    if (data.stop.type === ShipmentType.DELIVERY) {
      data.destination = stop.location;
    } else if (data.stop.type === ShipmentType.PICKUP) {
      data.source = stop.location;
    }

    await createStopShipmentQuery.mutateAsync(data);

    // Clear out search and previous form values after successful creation
    searchForm.setValue("code", "");
    setSearchResult(undefined);
    reset();
  }

  function RequirementsPicker() {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">Requirements</FormLabel>
        <FormGroup>
          {createableRequirementTypes.map((type) => (
            <FormControlLabel
              key={type}
              control={
                <Checkbox
                  name={type}
                  checked={watch("requirements")!.indexOf(type) !== -1}
                  onChange={(_ev, checked) => {
                    const requirements = new Set([
                      ...getValues("requirements")!,
                    ]);
                    if (checked) {
                      requirements.add(type);
                    } else {
                      requirements.delete(type);
                    }
                    setValue("requirements", [...requirements]);
                  }}
                />
              }
              label={requirementTypeString(type)}
            />
          ))}
        </FormGroup>
      </FormControl>
    );
  }

  function CreateStopShipmentForm() {
    const tagsQuery = useQuery<ListTagsResponse>("/tags", async () =>
      get("/tags")
    );

    return (
      <form style={{ flex: 1 }} onSubmit={handleSubmit(createStopShipment)}>
        <Grid container spacing={5} style={{ margin: 0 }}>
          <Grid item xs={4}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <FormTextField
                  name="code"
                  label="Code"
                  disabled={true}
                  control={control}
                  errors={errors}
                  InputProps={{ sx: { fontFamily: "Fragment Mono" } }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormSelect
                  name="stop.type"
                  label="Shipment Type"
                  control={control}
                  errors={errors}
                >
                  <MenuItem value={ShipmentType.DELIVERY}>Delivery</MenuItem>
                  <MenuItem value={ShipmentType.PICKUP}>Pickup</MenuItem>
                </FormSelect>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <RequirementsPicker />
          </Grid>
          <Grid item xs={4}>
            {tagsQuery.data ? (
              <Autocomplete
                multiple
                options={tagsQuery.data.tags.map((t) => t.name)}
                renderTags={(values, getTagProps) =>
                  values.map((label, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Chip
                      color="secondary"
                      label={label}
                      sx={{ marginX: 0.5 }}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(autocompleteParams) => (
                  <TextField {...autocompleteParams} label="Tags" />
                )}
                value={watch("tags")}
                onChange={(_, tags) => setValue("tags", tags)}
              />
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <Grid item xs={2}>
            <RButton
              type="submit"
              loading={createStopShipmentQuery.isLoading}
              startIcon={<AddCircle />}
            >
              Create
            </RButton>
          </Grid>
        </Grid>
      </form>
    );
  }

  function AddStopShipment() {
    const result = searchResult!;

    return (
      <Grid item container spacing={5}>
        <Grid item>
          <FormTextField
            name="code"
            label="Code"
            disabled={true}
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={3}>
          <FormSelect
            name="stop.type"
            label="Shipment Type"
            control={control}
            errors={errors}
          >
            <MenuItem value={ShipmentType.DELIVERY}>Delivery</MenuItem>
            <MenuItem value={ShipmentType.PICKUP}>Pickup</MenuItem>
          </FormSelect>
        </Grid>
        <Grid item>
          <RequirementsPicker />
        </Grid>
        <Grid item>
          <RButton
            startIcon={<AddCircle />}
            onClick={() => {
              createStopShipment({
                ...result,
                requirements: getValues("requirements"),
                stop: {
                  id: stop.id,
                  type: getValues("stop.type"),
                },
              } as UpsertShipmentRequest);
            }}
          >
            Add
          </RButton>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {!isStopDeparted(stop) && (
        <Grid container spacing={5}>
          <Grid item display="flex" xs={12}>
            <form
              onSubmit={searchForm.handleSubmit(search)}
              style={{ flex: 1, display: "flex", alignItems: "center" }}
            >
              <FormTextField
                name="code"
                control={searchForm.control}
                errors={searchForm.formState.errors}
                label="Search Shipment Code"
                rules={{
                  validate: (value) => {
                    return !!value.trim();
                  },
                }}
                style={{ flex: 1 }}
                disabled={searching}
                InputProps={{ sx: { fontFamily: "Fragment Mono" } }}
              />
              <IconButton
                size="large"
                disabled={searching}
                type="submit"
                sx={{ marginLeft: 1 }}
              >
                {searching ? (
                  <CircularProgress size={24} />
                ) : (
                  <Search color="primary" />
                )}
              </IconButton>
            </form>
          </Grid>
          {searchResult === null && <CreateStopShipmentForm />}
          {searchResult != null && <AddStopShipment />}
        </Grid>
      )}
      {stop.shipments.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Requirements</TableCell>
              <TableCell>Tags</TableCell>
              <TableCell width="10%"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stop.shipments.map((s) => (
              <TableRow key={s.id}>
                <TableCell sx={{ fontFamily: "Fragment Mono" }}>
                  {s.code}
                </TableCell>
                <TableCell>{shipmentTypeString(s.type)}</TableCell>
                <TableCell>
                  {s.requirements
                    .map((r) => r.type)
                    .sort()
                    .map(requirementTypeString)
                    .join(", ")}
                </TableCell>
                <TableCell>
                  {s.tags?.map((t) => (
                    <Chip
                      label={t}
                      key={t}
                      color="secondary"
                      sx={{ marginX: 0.5 }}
                    />
                  ))}
                </TableCell>
                <TableCell>
                  <RButton
                    color="secondary"
                    size="small"
                    onClick={() =>
                      removeRouteStopMutation.mutate(
                        {
                          stopId: stop.id,
                          shipmentId: s.id,
                        },
                        {
                          onSuccess: (data, variables) => {
                            onShipmentRemoved?.(variables.shipmentId);
                          },
                        }
                      )
                    }
                    loading={removeRouteStopMutation.isLoading}
                  >
                    Remove
                  </RButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};
