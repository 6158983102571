import AddCircle from "@mui/icons-material/AddCircle";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { DurationPicker } from "../../components/DurationPicker";
import { LoadingScreen } from "../../components/LoadingScreen";
import { Page } from "../../components/Page";
import { RButton } from "../../components/RButton";
import { useAvailableVehicles } from "../../hooks/queries/useAvailableVehicles";
import { useBackgroundJobMonitor } from "../../hooks/useBackgroundJobMonitor";
import { errorSnackbar, useSnackbar } from "../../hooks/useSnackbar";
import { get, post } from "../../lib/amplify";
import type {
  Appointment,
  AsyncEndpointResponse,
  BackgroundJobStatusResponse,
  Customer,
  EstimateRoutesRequest,
  EstimateRoutesResponse,
  OptimizationOptions,
  Vehicle,
  ZonesRequiringRouteResponse,
} from "../../shared/api_schema";
import { AppointmentDialog } from "../appointments/AppointmentDialog";
import { ScheduledAppointmentDialog } from "../appointments/ScheduledAppointmentDialog";
import { EstimatedRoutesDialog } from "./EstimatedRoutesDialog";
import { RouteBuilderAppointments } from "./RouteBuilderAppointments";
import { RouteBuilderMap } from "./RouteBuilderMap";
import {
  Stoplike,
  StoplikeType,
  appointmentStoplike,
  scheduledStoplike,
  sortStopLikes,
} from "./Stoplike";

function buildRequest(
  zoneId: string,
  stoplikes: Stoplike[],
  vehicles: Vehicle[],
  options?: OptimizationOptions
): EstimateRoutesRequest {
  return {
    zone: { id: zoneId },
    scheduledAppointments: stoplikes
      .filter((s) => s.type === StoplikeType.SCHEDULED_APPOINTMENT)
      .map((s) => ({ id: s.id })),
    appointments: stoplikes
      .filter((s) => s.type === StoplikeType.APPOINTMENT)
      .map((s) => ({ id: s.id })),
    vehicles: vehicles.map((v) => ({ id: v.id })),
    options,
  };
}

export const RouteBuilder: FC = () => {
  // All StopLikes (appointments)
  const [stoplikes, setStoplikes] = useState<Stoplike[]>([]);

  // StopLikes that are currently marked as skipped
  const [skippedStoplikes, setSkippedStoplikes] = useState<Stoplike[]>([]);

  // StopLikes that are "ready to optimize" (not currently marked as skipped)
  const readyStopLikes = useMemo(() => {
    return stoplikes.filter((s) => !skippedStoplikes.includes(s));
  }, [stoplikes, skippedStoplikes]);

  const [selectedVehicles, setSelectedVehicles] = useState<Array<Vehicle>>([]);
  const [estimatedRoutes, setEstimatedRoutes] =
    useState<EstimateRoutesResponse>();

  const [optimizerJobStatus, setOptimizerJobStatus] =
    useState<BackgroundJobStatusResponse<EstimateRoutesResponse>>();

  const [isOptimizerRunning, setIsOptimizerRunning] = useState(false);

  const [vehicleOpenOverride, setVehicleOpenOverride] =
    useState<DateTime | null>(null);
  const [vehicleCloseOverride, setVehicleCloseOverride] =
    useState<DateTime | null>(null);
  const [serviceTimeOverride, setServiceTimeOverride] = useState<number | null>(
    null
  );
  const [forceAllVehicles, setForceAllVehicles] = useState(false);
  const [deliveriesBeforePickups, setDeliveriesBeforePickups] = useState(false);

  const [addAppointmentOpen, setAddAppointmentOpen] = useState(false);
  const [editing, setEditing] = useState<Stoplike>();

  const { id: zoneId, date } = useParams();

  const zonesRequiringRoutesQuery = useQuery(
    ["zones_requiring_routes", date],
    async () =>
      get<ZonesRequiringRouteResponse>(`/zones/requiring_route?date=${date}`),
    {
      onSuccess: (data) => {
        const zone = data.zones.find((zone) => zone.id === zoneId)!;
        const stopLikes = [
          ...zone.appointments.map(appointmentStoplike),
          ...zone.scheduledAppointments.map(scheduledStoplike),
        ];

        const sortedStopLikes = sortStopLikes(stopLikes, skippedStoplikes);
        setStoplikes(sortedStopLikes);
      },
    }
  );

  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const availableVehiclesQuery = useAvailableVehicles(zoneId!, date!, {
    onSuccess: (data) => {
      setSelectedVehicles(data.vehicles);
    },
  });

  const estimateRoutesMutation = useMutation(
    async (payload: EstimateRoutesRequest) =>
      post(`/routes/estimate?date=${date}`, payload, { async: true }),
    {
      onMutate: () => {
        setIsOptimizerRunning(true);
      },
      onSuccess: (data: AsyncEndpointResponse) => {
        setOptimizerJobStatus(data);
      },
      onError: (err: any) => {
        errorSnackbar(err, snackbar);
        setIsOptimizerRunning(false);
      },
    }
  );

  useBackgroundJobMonitor(optimizerJobStatus, {
    onComplete: (job) => {
      console.log("onComplete", job);
      setEstimatedRoutes(job.result);
      setIsOptimizerRunning(false);
    },
    onFailed: (job) => {
      errorSnackbar(
        job.error ?? "There was an error optimizing this route",
        snackbar
      );
      setIsOptimizerRunning(false);
    },
  });

  useEffect(() => {
    if (zonesRequiringRoutesQuery.data) {
      const currentZone = zonesRequiringRoutesQuery.data.zones.find(
        (z) => z.id === zoneId
      )!;

      if (currentZone.optimizerJobInProgress) {
        get<BackgroundJobStatusResponse<EstimateRoutesResponse>>(
          `/jobs/${currentZone.optimizerJobInProgress}`
        ).then((data) => {
          setIsOptimizerRunning(true);
          setOptimizerJobStatus(data);
        });
      }
    }
  }, [zoneId, zonesRequiringRoutesQuery.data]);

  function appointmentAdded(_appointment: Appointment) {
    zonesRequiringRoutesQuery.refetch();
  }

  function appointmentUpdated(_appointment: Appointment) {
    zonesRequiringRoutesQuery.refetch();
  }

  // Optimization: do useCallback here so every time we render RouteBuilder we don't re-render the whole appointments list needlessly
  const removeStoplike = useCallback((stoplike: Stoplike) => {
    setStoplikes((prev) => {
      return prev!.filter((s) => s.id !== stoplike.id);
    });
  }, []);

  const skipStoplike = useCallback((stoplike: Stoplike) => {
    setSkippedStoplikes((prev) => [...prev, stoplike]);
  }, []);

  const unskipStoplike = useCallback((stoplike: Stoplike) => {
    setSkippedStoplikes((prev) => prev.filter((s) => s.id !== stoplike.id));
  }, []);

  const skipAllStoplike = useCallback(() => {
    setSkippedStoplikes((prev) => [...prev, ...stoplikes]);
  }, [stoplikes]);

  const routeBuilderMap = useMemo(
    () => (
      <RouteBuilderMap
        stoplikes={readyStopLikes}
        vehicles={selectedVehicles}
        onStoplikeClicked={(stoplike) => setEditing(stoplike)}
      />
    ),
    [selectedVehicles, readyStopLikes]
  );

  const routeBuilderAppointments = useMemo(
    () => (
      <RouteBuilderAppointments
        stoplikes={sortStopLikes(stoplikes.slice(), skippedStoplikes)}
        skippedStoplikes={skippedStoplikes}
        onSkip={skipStoplike}
        onUnskip={unskipStoplike}
        onStoplikeClicked={(stoplike: Stoplike) => setEditing(stoplike)}
      />
    ),
    [stoplikes, skipStoplike, unskipStoplike, skippedStoplikes]
  );

  if (
    zonesRequiringRoutesQuery.isFetching ||
    availableVehiclesQuery.isFetching ||
    stoplikes == null
  ) {
    return <LoadingScreen />;
  }

  const availableVehicles = availableVehiclesQuery.data!.vehicles;
  const currentZone = zonesRequiringRoutesQuery.data!.zones.find(
    (z) => z.id === zoneId
  )!;

  function handleToggle(vehicleId: string) {
    setSelectedVehicles((prev) => {
      if (prev.find((v) => v.id === vehicleId)) {
        return prev.filter((v) => v.id !== vehicleId);
      } else {
        return [...prev, availableVehicles.find((v) => v.id === vehicleId)!];
      }
    });
  }

  function handleSkipReset() {
    setSkippedStoplikes([]);
  }

  function makeOptions() {
    const options = {
      ...(deliveriesBeforePickups && { deliveriesBeforePickups: true }),
      ...(forceAllVehicles && { forceAllVehicles: true }),
      ...(vehicleOpenOverride && {
        vehicleOpenOverride: vehicleOpenOverride.toLocaleString(
          DateTime.TIME_24_SIMPLE
        ),
      }),
      ...(vehicleCloseOverride && {
        vehicleCloseOverride: vehicleCloseOverride.toLocaleString(
          DateTime.TIME_24_SIMPLE
        ),
      }),
      ...(serviceTimeOverride !== null && {
        serviceTimeOverride,
      }),
    };

    // Don't send an empty object for options -- just don't submit any if we don't have any to send
    return Object.keys(options).length > 0 ? options : undefined;
  }

  const vehiclesAvailable = availableVehicles.length > 0;

  return (
    <Page
      title="Build Route"
      subTitle={`${currentZone.name} - ${DateTime.fromISO(date!).toFormat(
        "EEEE MMMM d"
      )}`}
    >
      <Box display="flex" minHeight={300} gap={5}>
        <Box display="flex" flexDirection="column">
          <Typography variant="h4" mt={5}>
            Vehicles
          </Typography>
          {vehiclesAvailable ? (
            <List>
              {availableVehicles.map((v) => {
                return (
                  <ListItem key={v.id} disablePadding>
                    <ListItemButton onClick={() => handleToggle(v.id)}>
                      <ListItemIcon>
                        <Checkbox
                          checked={
                            selectedVehicles.find(
                              (vehicle) => vehicle.id === v.id
                            ) !== undefined
                          }
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={v.name}></ListItemText>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <Alert severity="error">No vehicles available!</Alert>
          )}
          <Typography variant="h4" mt={3}>
            Options
          </Typography>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <TimePicker
              label="Vehicle Open Override"
              ampm={true}
              value={vehicleOpenOverride}
              onChange={(value) => setVehicleOpenOverride(value)}
              slotProps={{ textField: { margin: "dense" } }}
            />
            <TimePicker
              label="Vehicle Close Override"
              ampm={true}
              value={vehicleCloseOverride}
              onChange={(value) => setVehicleCloseOverride(value)}
              slotProps={{ textField: { margin: "dense" } }}
            />
          </LocalizationProvider>
          <FormControl margin="dense">
            <DurationPicker
              name="service"
              label="Service Time Override"
              value={serviceTimeOverride}
              onChange={(e) =>
                setServiceTimeOverride(
                  isNaN(parseInt(e.target.value)) ? "" : e.target.value
                )
              }
            />
          </FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={forceAllVehicles}
                  onChange={(e) => setForceAllVehicles(e.target.checked)}
                />
              }
              label="Force All Vehicles"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={deliveriesBeforePickups}
                  onChange={(e) => setDeliveriesBeforePickups(e.target.checked)}
                />
              }
              label="Deliveries Before Pickups"
            />
          </FormGroup>
          <RButton
            onClick={() => {
              setIsOptimizerRunning(true);
              estimateRoutesMutation.mutate(
                buildRequest(
                  zoneId!,
                  readyStopLikes,
                  selectedVehicles,
                  makeOptions()
                )
              );
            }}
            loading={isOptimizerRunning}
            size="large"
            color="primary"
            type="submit"
            disabled={
              !vehiclesAvailable ||
              !stoplikes.length ||
              estimateRoutesMutation.isLoading
            }
          >
            Optimize
          </RButton>
        </Box>
        <Box flexGrow={1}>{routeBuilderMap}</Box>
      </Box>
      <Box display="flex" my={5}>
        <Typography variant="h4" flexGrow={1}>
          Appointments
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setAddAppointmentOpen(true);
          }}
          startIcon={<AddCircle />}
        >
          Add Appointment
        </Button>
      </Box>
      <Box sx={{ textAlign: "right", paddingBottom: 2 }}>
        <Typography variant="body1" sx={{ fontSize: 14 }}>
          {skippedStoplikes.length > 0 ? (
            <>
              {skippedStoplikes.length} appointments skipped
              <Button
                onClick={handleSkipReset}
                variant="outlined"
                size="small"
                sx={{ marginLeft: 2 }}
              >
                Reset
              </Button>
            </>
          ) : (
            <Button variant="outlined" size="small" onClick={skipAllStoplike}>
              Skip All
            </Button>
          )}
        </Typography>
      </Box>

      {routeBuilderAppointments}

      {addAppointmentOpen && (
        <AppointmentDialog
          title="Add Appointment"
          zone={currentZone}
          onClose={() => setAddAppointmentOpen(false)}
          onAdd={appointmentAdded}
        />
      )}
      {editing?.appointment && (
        <AppointmentDialog
          title="Edit Appointment"
          appointment={editing.appointment}
          onUpdate={appointmentUpdated}
          onClose={(removedId) => {
            if (removedId) {
              removeStoplike(editing);
            }
            setEditing(undefined);
          }}
        />
      )}
      {editing?.scheduledAppointment && (
        <ScheduledAppointmentDialog
          appointment={editing.scheduledAppointment}
          customer={editing.scheduledAppointment.customer as Customer}
          onClose={(removedId) => {
            if (removedId) {
              removeStoplike(editing);
            }
            setEditing(undefined);
          }}
        />
      )}
      {estimatedRoutes && (
        <EstimatedRoutesDialog
          estimatedRoutes={estimatedRoutes}
          onClose={() => setEstimatedRoutes(undefined)}
          onConfirm={() => navigate("/")}
        />
      )}
    </Page>
  );
};
