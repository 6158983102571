import AddCircle from "@mui/icons-material/AddCircle";
import { Box, Button, Grid } from "@mui/material";
import type { GridColDef } from "@mui/x-data-grid";
import type { FC } from "react";
import { useState } from "react";
import { Page } from "../../components/Page";
import { PaginatedDataGrid } from "../../components/PaginatedDataGrid";
import { RDialog } from "../../components/RDialog";
import type { Zone } from "../../shared/api_schema";
import { friendlyTzName, toLocale } from "../../shared/frontend";
import { ZoneForm } from "./ZoneForm";

export const Zones: FC = () => {
  const [zoneDialogOpen, setZoneDialogOpen] = useState<boolean>(false);
  const [editingZone, setEditingZone] = useState<Zone>();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "timeZone",
      headerName: "Time Zone",
      flex: 1,
      valueFormatter: ({ value }) => friendlyTzName(value as string),
    },
    {
      field: "open",
      headerName: "Open Time",
      flex: 1,
      valueGetter: ({ row }) => {
        return toLocale(row.open, row.timeZone);
      },
    },
  ];

  return (
    <Page>
      <Box marginY={4}>
        <Grid spacing={2} container justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={() => {
                setEditingZone(undefined);
                setZoneDialogOpen(true);
              }}
              variant="contained"
              color="primary"
              startIcon={<AddCircle />}
            >
              Add Zone
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <PaginatedDataGrid
          model="zones"
          columns={columns}
          onRowClick={(params) => {
            setEditingZone(params.row as Zone);
            setZoneDialogOpen(true);
          }}
        />
      </Box>

      <RDialog
        open={zoneDialogOpen}
        title={`${editingZone ? "Edit" : "Add"} Zone`}
        closeCallback={() => setZoneDialogOpen(false)}
      >
        <ZoneForm
          zone={editingZone}
          onSubmitSuccess={() => {
            setZoneDialogOpen(false);
          }}
        />
      </RDialog>
    </Page>
  );
};
