import type { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { Page } from "../../components/Page";
import { PaginatedDataGrid } from "../../components/PaginatedDataGrid";
import { TimeSwap } from "../../components/TimeSwap";
import { fullName } from "../../shared/frontend";

const columns: GridColDef[] = [
  {
    headerName: "Date",
    field: "date",
    valueGetter: (params: GridValueGetterParams) =>
      DateTime.fromISO(params.row.begin.expected)
        .setZone(params.row.zone.timeZone)
        .toISODate(),
    flex: 1,
  },
  {
    headerName: "Begin",
    field: "begin",
    renderCell: (params) => (
      <TimeSwap pair={params.row.begin} tz={params.row.zone.timeZone} />
    ),
    flex: 1,
  },
  {
    headerName: "End",
    field: "end",
    renderCell: (params) => (
      <TimeSwap pair={params.row.end} tz={params.row.zone.timeZone} />
    ),
    flex: 1,
  },
  {
    field: "zone",
    headerName: "Zone",
    valueGetter: (params: GridValueGetterParams) => params.row.zone.name,
    flex: 1,
  },
  {
    headerName: "Driver",
    field: "driver",
    valueGetter: (params: GridValueGetterParams) =>
      params.row.driver ? fullName(params.row.driver) : null,
    flex: 1,
  },
  {
    headerName: "Dispatcher",
    field: "dispatcher",
    valueGetter: (params: GridValueGetterParams) =>
      params.row.dispatcher ? fullName(params.row.dispatcher) : null,
    flex: 1,
  },
];

export const Routes = () => {
  const navigate = useNavigate();
  return (
    <Page>
      <PaginatedDataGrid
        model="routes"
        columns={columns}
        onRowClick={(params) => {
          navigate(`/routes/${params.row.id}`);
        }}
      />
    </Page>
  );
};
