import {
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import { isPaymentRequiredError } from "./components/PaymentRequired";
import RouterErrorBoundary from "./components/RouterErrorBoundary";
import { AuthProvider } from "./hooks/useAuth";
import { SnackbarProvider } from "./hooks/useSnackbar";
import { AppRouter } from "./router/AppRouter";
import { Colors } from "./shared/frontend";
import "./shared/lib/luxon";

export default function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <CssBaseline />
              <RouterErrorBoundary>
                <AppRouter />
              </RouterErrorBoundary>
              {/* <ReactQueryDevtools /> */}
            </AuthProvider>
          </QueryClientProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Neurial';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('/fonts/NeurialGrotesk-Regular.woff2') format('woff2'), url('/fonts/NeurialGrotesk-Regular.woff') format('woff');
        }

        @font-face {
          font-family: 'Neurial';
          font-style: bold;
          font-display: swap;
          font-weight: 600;
          src: url('/fonts/NeurialGrotesk-Medium.woff2') format('woff2'), url('/fonts/NeurialGrotesk-Medium.woff') format('woff');
        }
      `,
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        color: "primary",
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "12px 24px",
        },
      },
    },
  },
  spacing: 4,
  palette: {
    primary: {
      light: Colors.BRAND_GREEN_80,
      main: Colors.BRAND_GREEN,
      dark: Colors.DARK_GREEN,
    },
    secondary: {
      light: Colors.GOLD_88,
      main: Colors.GOLD,
    },
    success: { main: Colors.SUCCESS },
    info: { main: Colors.INFO },
    error: { main: Colors.ERROR },
  },
  typography: {
    fontFamily: "Neurial",
    h1: {
      fontSize: "1.7rem",
      textTransform: "uppercase",
      fontWeight: 600,
      color: Colors.GREY_SLATE,
    },
    h2: {
      fontSize: "1.4rem",
      lineHeight: 1.4,
      color: Colors.GREY_SMOKE,
    },
    h3: {
      fontSize: "1.6rem",
      marginTop: 10,
      marginBottom: 10,
    },
    h4: {
      fontSize: "1.3rem",
      marginTop: 8,
      marginBottom: 8,
    },
  },
});

// If we get a 402 (payment required) on any React Query call,
// send it to the error boundary so we render out a payment required page
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: isPaymentRequiredError,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
