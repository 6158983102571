import { Box, Button, Card, Grid, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import { LoadingScreen } from "../components/LoadingScreen";
import { useAuth } from "../hooks/useAuth";
import type { Organization } from "../shared/api_schema";

const useStyles = makeStyles(() =>
  createStyles({
    message: {
      marginTop: 14,
      marginBottom: 14,
      display: "block",
    },
  })
);

export const OrganizationSelection = ({
  onSelection,
}: {
  onSelection: (organizationId: string) => Promise<void>;
}) => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const organizations = currentUser?.organizations;
  const [loading, setLoading] = useState(false);

  // If we only have 1 organization we'll auto select that one
  useEffect(() => {
    if (organizations && organizations.length === 1) {
      onSelection(organizations[0].id);
    }
  }, [organizations, onSelection]);

  const multipleOrganizations = organizations && organizations.length > 1;

  if (loading || !multipleOrganizations) {
    return <Loading />;
  }

  return (
    <Box>
      <Grid container justifyContent="center" alignItems="center">
        <Typography className={classes.message}>
          You belong to multiple organizations.
          <br /> Choose an organization to sign in:
        </Typography>
        {organizations ? (
          organizations.map((organization) => (
            <OrganizationCard
              key={organization.id}
              organization={organization}
              onSelection={async (organizationId: string) => {
                setLoading(true);
                await onSelection(organizationId);
              }}
            />
          ))
        ) : (
          <Box padding={12}>
            <LoadingScreen />
          </Box>
        )}
      </Grid>
    </Box>
  );
};

const useCardStyles = makeStyles(() =>
  createStyles({
    selectButton: {},
    organizationName: {
      fontSize: 18,
      flex: 1,
    },
    card: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 20,
      marginBottom: 8,
    },
  })
);

const OrganizationCard = ({
  organization,
  onSelection,
}: {
  organization: Organization;
  onSelection: (organizationId: string) => void;
}) => {
  const classes = useCardStyles();

  return (
    <Card className={classes.card}>
      <Typography
        className={classes.organizationName}
        variant="h5"
        component="h2"
      >
        {organization.name}
      </Typography>
      <Button
        size="small"
        className={classes.selectButton}
        onClick={() => onSelection(organization.id)}
      >
        Select
      </Button>
    </Card>
  );
};

const Loading = () => {
  return (
    <Box padding={12}>
      <LoadingScreen />
    </Box>
  );
};
