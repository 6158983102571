import { Alert, Box, Button, Grid } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { BrandedCenteredBox } from "../../components/BrandedCenteredBox";
import { LoadingScreen } from "../../components/LoadingScreen";
import { RButton } from "../../components/RButton";
import { FormTextField } from "../../components/forms/FormTextField";
import { MobileLink } from "../../components/forms/MobileLink";
import { useAuth } from "../../hooks/useAuth";
import { useInvitationSignUpRedirect } from "../../hooks/useInvitation";
import { useFullQueryString } from "../../hooks/useQueryString";
import { NoOrganizationError } from "../../shared/frontend/errors";
import { OrganizationSelection } from "../OrganizationSelection";
import { InviteBanner } from "./InviteBanner";

function stateValue(state: unknown, key: string) {
  if (state == null || typeof state !== "object") {
    return;
  }

  const record = state as Record<string, string>;
  return record[key];
}

export const SignIn = () => {
  const {
    currentUser,
    login,
    isCheckingAuth,
    organizationSelectionRequired,
    setOrganizationSelectionRequired,
    refetchCurrentUser,
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [authError, setAuthError] = useState<string>();
  const [emailConfirmationRequired, setEmailConfirmationRequired] =
    useState(false);
  const fullQueryString = useFullQueryString();

  const moreInfoRequired =
    organizationSelectionRequired || emailConfirmationRequired;

  // Redirect to Sign Up with invite if needed
  useInvitationSignUpRedirect();

  interface SignInForm {
    email: string;
    password: string;
  }

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<SignInForm>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  // If we already have a currentUser, either bring them to the dashboard the path they requested
  // However, if this is an OAuth connection request, the user will need to re-authenticate
  useEffect(() => {
    if (!currentUser || organizationSelectionRequired) {
      return;
    }

    navigate(stateValue(location.state, "from") ?? "/dashboard");
  }, [currentUser, navigate, location.state, organizationSelectionRequired]);

  useEffect(() => {
    if (currentUser) {
      setValue("email", currentUser.email);
    }
  }, [currentUser, setValue]);

  async function formSubmit({ email, password }: SignInForm) {
    setAuthError(undefined);

    try {
      const user = await login({
        credentials: { email, password },
      });

      if (
        "challengeName" in user &&
        user.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        navigate("/change_password");
        return;
      }

      // else -> user will be sent where they need to go when `currentUser`
      // query returns and becomes populated
    } catch (error: any) {
      if (error instanceof NoOrganizationError) {
        console.log("No org");
        return;
      } else if (error.code === "UserNotConfirmedException") {
        setEmailConfirmationRequired(true);
      } else {
        setAuthError(error.message);
      }
    }
  }

  // If we have a defined currentUser, we're in the process of redirecting away from here
  // Without this, we temporarily show the login form while we wait to redirect after navigate
  if (isCheckingAuth || (currentUser && !organizationSelectionRequired)) {
    return (
      <Box sx={{ height: "100vh", width: "100vw" }}>
        <LoadingScreen />
      </Box>
    );
  }

  // Deep-link into mobile if they're confirming on a mobile device
  if (isMobile) {
    return <MobileLink fullPage path={`signin${fullQueryString}`} />;
  }

  return (
    <Box>
      <BrandedCenteredBox>
        <InviteBanner message="Please sign in to continue." />
        {stateValue(location.state, "message") && (
          <Box my={4}>
            <Alert severity="info">
              {stateValue(location.state, "message")}
            </Alert>
          </Box>
        )}
        {authError && (
          <Box my={4}>
            <Alert severity="error">{authError}</Alert>
          </Box>
        )}
        <form noValidate autoComplete="off" onSubmit={handleSubmit(formSubmit)}>
          <Grid container>
            <Grid item xs={12}>
              <FormTextField
                control={control}
                errors={errors}
                name="email"
                label="Email"
                disabled={moreInfoRequired}
                rules={{
                  required: "An email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
              />
              <br />
              <br />
            </Grid>
            {!moreInfoRequired && (
              <>
                <Grid item xs={12}>
                  <FormTextField
                    control={control}
                    errors={errors}
                    name="password"
                    type="password"
                    label="Password"
                    rules={{ required: "Please enter a password" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box mt={6}>
                    <RButton color="primary" fullWidth type="submit">
                      {"Login"}
                    </RButton>
                  </Box>
                </Grid>
              </>
            )}
            <Grid item xs={12}></Grid>
          </Grid>
        </form>
        {emailConfirmationRequired && !currentUser && null}
        {currentUser && organizationSelectionRequired && !authError && (
          <OrganizationSelection
            onSelection={async (organizationId: string) => {
              try {
                const org = currentUser.organizations.find(
                  (org) => org.id === organizationId
                );

                if (org) {
                  currentUser.activeOrganization = org;
                  setOrganizationSelectionRequired(false);
                } else {
                  setAuthError("Invalid organization selection");
                }
              } catch (error) {
                if (error instanceof Error) {
                  setAuthError(error.message);
                } else {
                  console.log(error);
                }
              }
            }}
          />
        )}
        {organizationSelectionRequired && authError && (
          <Box my={4} textAlign={"center"}>
            <Button
              onClick={() => {
                // Head back to root with no OAuth querystring params or previous state regarding
                // the OAuth flow intact.
                window.location.replace("/");
              }}
            >
              Return to sign in
            </Button>
          </Box>
        )}
      </BrandedCenteredBox>
      <FooterLinks />
    </Box>
  );
};

const FooterLinks = () => {
  const classes = makeStyles(() =>
    createStyles({
      root: {
        display: "flex",
        justifyContent: "center",
        padding: "10px 0",
      },
    })
  )();

  const fullQueryString = useFullQueryString();
  const { currentUser } = useAuth();

  return (
    <Box className={classes.root}>
      {!currentUser && (
        <Button
          href={`/sign_up${fullQueryString}`}
          variant="text"
          color="primary"
        >
          Sign Up
        </Button>
      )}
      <Button href="/forgot_password" variant="text" color="primary">
        Forgot Password?
      </Button>
    </Box>
  );
};
