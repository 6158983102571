export enum UserRole {
  ADMIN = "admin",
  DISPATCHER = "dispatcher",
  DRIVER = "driver",
}

function roleValue(role: UserRole): number {
  switch (role) {
    case UserRole.DRIVER:
      return 0;
    case UserRole.DISPATCHER:
      return 1;
    case UserRole.ADMIN:
      return 2;
    default:
      throw `Unrecognized role: ${role}`;
  }
}

export const compareRole = (a: UserRole, b: UserRole) =>
  Math.sign(roleValue(a) - roleValue(b));

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

// When given an organization context, users have roles
export type UserWithRole = User & { role: UserRole };
