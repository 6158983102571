import type { DataGridProps, GridColDef } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import type { FC } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { get } from "../lib/amplify";
import type { CountResponse } from "../shared/api_schema";
import { LoadingScreen } from "./LoadingScreen";

const PAGE_SIZE = 10;

export const PaginatedDataGrid: FC<
  Partial<Omit<DataGridProps, "pagination">> & {
    model: string; // Plural, i.e. "customers"
    columns: GridColDef[];
  }
> = ({ model, columns, ...rest }) => {
  const [skip, setSkip] = useState(0);

  const countQuery = useQuery<CountResponse>([model, "count"], async () =>
    get(`/${model}?count=true`)
  );

  const dataQuery = useQuery(
    [model, skip],
    async () => get(`/${model}?skip=${skip}&take=${PAGE_SIZE}`),
    { keepPreviousData: true, enabled: (countQuery.data?.count ?? 0) > 0 }
  );

  function forceUnsortable(columns: GridColDef[]): GridColDef[] {
    return columns.map((c) => ({ ...c, sortable: false }));
  }

  // Not allowed to return `undefined` for `rowCount`
  if (countQuery.isFetching) {
    return <LoadingScreen />;
  }

  return (
    <DataGrid
      loading={dataQuery.isFetching}
      rows={dataQuery.data?.[model] ?? []}
      columns={forceUnsortable(columns)}
      disableRowSelectionOnClick
      autoHeight
      paginationMode="server"
      paginationModel={{ page: skip / PAGE_SIZE, pageSize: PAGE_SIZE }}
      onPaginationModelChange={({ page, pageSize }) => setSkip(page * pageSize)}
      pageSizeOptions={[PAGE_SIZE]}
      rowCount={countQuery.data!.count}
      {...rest}
    />
  );
};
