import {
  Alert,
  Box,
  MenuItem,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { RButton } from "../../components/RButton";
import { FormDateTimePicker } from "../../components/forms/FormDateTimePicker";
import { FormSelect } from "../../components/forms/FormSelect";
import { useSnackbar } from "../../hooks/useSnackbar";
import { post } from "../../lib/amplify";
import {
  CreateTrackingEventRequest,
  CreateTrackingEventResponse,
  DeepShipmentV2,
  ShipmentExceptionType,
  Stop,
  TrackingEventType,
} from "../../shared/api_schema";
import {
  exceptionTypeString,
  trackingEventTypeString,
} from "../../shared/frontend";

export const AddTrackingEventForm = ({
  shipment,
}: {
  shipment: DeepShipmentV2;
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm<Omit<CreateTrackingEventRequest, "stop">>({
    defaultValues: {
      type: "" as TrackingEventType,
      subtype: "" as ShipmentExceptionType,
      occurredAt: DateTime.now().toISO(),
    },
  });

  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [showSubtype, setShowSubtype] = useState(false);
  const [stopId, setStopId] = useState(shipment.stops[0]?.id);

  const formSubmit = async (data: CreateTrackingEventRequest) => {
    setLoading(true);

    try {
      await post<CreateTrackingEventResponse, CreateTrackingEventRequest>(
        `/shipments/${shipment.id}/tracking_events`,
        {
          ...data,
          ...(stopId && { stop: { id: stopId } }),
        }
      );

      queryClient.invalidateQueries(["shipment", shipment.id]);
      reset();
      snackbar.show("Tracking event added!");
    } catch (error) {
      snackbar.show("There was an error adding this tracking event", "error");
    } finally {
      setLoading(false);
    }
  };

  function handleEventTypeSelectChange(e: SelectChangeEvent<any>) {
    const eventType = e.target.value;

    if (eventType === TrackingEventType.EXCEPTION) {
      setShowSubtype(true);
    } else {
      setShowSubtype(false);
      // Reset this value so a subtype isn't sent with non-exception tracking events
      setValue("subtype", undefined);
    }
  }

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(formSubmit)}>
      <Box display="flex" flexDirection="column" padding={4} gap={4}>
        <Box display="flex" gap={4}>
          <Box flex={1}>
            <FormDateTimePicker
              name="occurredAt"
              label="Occurred"
              control={control}
              errors={errors}
            />
          </Box>
          <Box flex={1}>
            <FormSelect
              control={control}
              errors={errors}
              name="type"
              label="Type"
              onChange={handleEventTypeSelectChange}
              rules={{ required: "A type is required" }}
            >
              {Object.values(TrackingEventType).map((r) => (
                <MenuItem key={r} value={r}>
                  {trackingEventTypeString(r)}
                </MenuItem>
              ))}
            </FormSelect>
          </Box>
          <Box flex={1}>
            <FormSelect
              disabled={!showSubtype}
              control={control}
              errors={errors}
              name="subtype"
              label="Exception Type"
              rules={{
                required: showSubtype
                  ? "An exception type is required"
                  : undefined,
              }}
            >
              {Object.values(ShipmentExceptionType).map((r) => (
                <MenuItem key={r} value={r}>
                  {exceptionTypeString(r)}
                </MenuItem>
              ))}
            </FormSelect>
          </Box>
        </Box>
        {!stopId && (
          <Alert severity="warning">
            No stop selected for the tracking event. The event will be assumed
            to take place at your depot.
          </Alert>
        )}
        <Box display="flex" gap={4}>
          <Box flex={2}>
            <TextField
              select
              fullWidth
              label="Stop"
              value={stopId}
              onChange={(ev) => setStopId(ev.target.value)}
              SelectProps={{ displayEmpty: true }}
            >
              {shipment.stops.map((s) => {
                const stop = s as Stop;
                const t = DateTime.fromISO(
                  stop.arrive.actual ?? stop.arrive.expected ?? stop.open,
                  { zone: stop.location.timeZone }
                );

                return (
                  <MenuItem key={stop.id} value={stop.id}>
                    <pre style={{ display: "inline" }}>{t.toISODate()} </pre>
                    {stop.location.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
          <Box display="flex" alignItems="center">
            <RButton
              color="info"
              disabled={!stopId}
              onClick={() => setStopId("")}
            >
              Clear Stop
            </RButton>
          </Box>
          <Box display="flex" alignItems="center">
            <RButton loading={loading} type="submit">
              Save
            </RButton>
          </Box>
        </Box>
      </Box>
    </form>
  );
};
