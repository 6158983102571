import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { create } from "zustand";
import { get } from "../lib/amplify";
import {
  getUserLocalStorage,
  setUserLocalStorage,
} from "../lib/user_local_storage";
import type { CountResponse, Message } from "../shared/api_schema";
import { useAuth } from "./useAuth";

const READ_MESSAGES_KEY = "readMessageIds";

const useUnreadMessageCount = create<{
  unreadMessageCount: number;
  setUnreadMessageCount: (count: number) => void;
}>((set) => ({
  unreadMessageCount: 0,
  setUnreadMessageCount: (count) => set({ unreadMessageCount: count }),
}));

export function useMessageHelpers() {
  const { currentUser } = useAuth();

  const messageCountQuery = useQuery(["messages", "count"], async () =>
    get<CountResponse>("/messages?count=true")
  );

  // Read message IDs
  const [readMessageIds, setReadMessageIds] = useState<Set<string>>();

  // Unread message count
  const { unreadMessageCount, setUnreadMessageCount } = useUnreadMessageCount();

  // Bring in the read message IDs when we get a local storage key
  useEffect(() => {
    if (!currentUser) return;
    getUserLocalStorage<string[]>(READ_MESSAGES_KEY).then((ids) =>
      setReadMessageIds(new Set(ids ?? []))
    );
  }, [currentUser]);

  // Write out the read message IDs when the set changes
  useEffect(() => {
    if (!currentUser || !readMessageIds) return;
    setUserLocalStorage(READ_MESSAGES_KEY, Array.from(readMessageIds));
  }, [currentUser, readMessageIds]);

  // Set the unread message count when the total message query count comes back
  useEffect(() => {
    if (!messageCountQuery.data || !readMessageIds) {
      return;
    }

    // Prevent a negative count which can happen if localStorage has more IDs than is actually in the database
    // This can happen, for example, if the server were to have messages cleared with IDs remaining in localStorage
    setUnreadMessageCount(
      Math.max(0, messageCountQuery.data.count - readMessageIds.size)
    );
  }, [messageCountQuery.data, readMessageIds, setUnreadMessageCount]);

  function setMessageRead(message: Message) {
    if (!readMessageIds) {
      return;
    }
    setReadMessageIds(new Set(Array.from(readMessageIds).concat(message.id)));
  }

  function isMessageRead(message: Message) {
    if (!readMessageIds) {
      return;
    }
    return readMessageIds.has(message.id);
  }

  return {
    setMessageRead,
    isMessageRead,
    unreadMessageCount,
  };
}
