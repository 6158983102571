import { useEffect, useRef, useState } from "react";
import { get } from "../lib/amplify";
import { BackgroundJobStatusResponse } from "../shared/api_schema";

type BackgroundJobMonitorOptions<ResultType> = {
  onComplete?: (job: BackgroundJobStatusResponse<ResultType>) => void;
  onFailed?: (job: BackgroundJobStatusResponse<ResultType>) => void;
  initialIntervalMs?: number;
  maxIntervalMs?: number;
};

export const useBackgroundJobMonitor = <ResultType>(
  initialJobStatus: BackgroundJobStatusResponse<ResultType> | undefined,
  options?: BackgroundJobMonitorOptions<ResultType>
) => {
  const initialIntervalMs = useRef(options?.initialIntervalMs ?? 1000);
  const maxIntervalMs = useRef(options?.maxIntervalMs ?? 30000);

  const [jobStatus, setJobStatus] = useState<
    BackgroundJobStatusResponse<ResultType> | undefined
  >(initialJobStatus ?? undefined);

  useEffect(() => setJobStatus(initialJobStatus), [initialJobStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const checkJobStatus = async () => {
      if (jobStatus?.status === "queued" || jobStatus?.status === "running") {
        const currentStatus = await get<
          BackgroundJobStatusResponse<ResultType>
        >(`/jobs/${jobStatus.id}`);

        setJobStatus(currentStatus);

        switch (currentStatus.status) {
          case "complete":
            options?.onComplete?.(currentStatus);
            break;

          case "failed":
            options?.onFailed?.(jobStatus);
            break;

          default:
            initialIntervalMs.current *= 1.25; // Increase delay by 25%

            // Don't let the interval delay get too long
            if (initialIntervalMs.current > maxIntervalMs.current) {
              initialIntervalMs.current = maxIntervalMs.current;
            }

            timeoutId = setTimeout(checkJobStatus, initialIntervalMs.current);
            break;
        }
      }
    };

    timeoutId = setTimeout(checkJobStatus, initialIntervalMs.current);

    // Clear timeout on component unmount
    return () => {
      clearTimeout(timeoutId);
    };
  }, [jobStatus, options]);

  return { jobStatus };
};
