import { Box, Container, Typography } from "@mui/material";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { LoadingScreen } from "../../components/LoadingScreen";
import { SignOutButton } from "../../components/SignOutButton";
import { useAuth } from "../../hooks/useAuth";
import { useInvitation } from "../../hooks/useInvitation";
import { get, post } from "../../lib/amplify";
import type {
  CreateOrganizationRequest,
  CreateOrganizationResponse,
  CreateVehicleRequest,
  CreateZoneResponse,
  ListVehiclesResponse,
  ListZonesResponse,
  Zone,
} from "../../shared/api_schema";
import { InviteAcceptance } from "../InviteAcceptance";
import { useSignUpWizardStore } from "../users/ConfirmEmail";
import { VehicleForm } from "../vehicles/VehicleForm";

const DEFAULT_ORG_NAME = "Default Organization";
const DEFAULT_ZONE_NAME = "Default Zone";
const DEFAULT_VEHICLE_NAME = "Default Vehicle";

enum OnboardingStep {
  NAME_ORGANIZATION,
  ADD_ZONE,
  ADD_VEHICLE,
}

const WizardStep: FC<PropsWithChildren<{ heading: string; detail: string }>> = (
  props
) => {
  return (
    <Container
      maxWidth="md"
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <Typography variant="h1">{props.heading}</Typography>
        <Box my={6}>
          <Typography variant="body1">{props.detail}</Typography>
        </Box>
        {props.children}
      </div>
      <Box position="absolute" top="30px" right="30px">
        <SignOutButton />
      </Box>
    </Container>
  );
};

const CreateOrganization: FC<{
  onComplete: () => void;
}> = (props) => {
  const { accountDefaults } = useSignUpWizardStore();
  const createOrganizationMutation = useMutation(async (name: string) =>
    post<CreateOrganizationResponse, CreateOrganizationRequest>(
      `/organizations`,
      { name }
    )
  );

  useEffect(() => {
    // Automatically create an organization using the name supplied via querystring to the
    // original sign up call, if one exists
    const organizationName =
      accountDefaults?.organizationName ?? DEFAULT_ORG_NAME;

    createOrganizationMutation.mutate(organizationName, {
      onSuccess: () => props.onComplete(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDefaults?.organizationName]);

  return <LoadingScreen message="Creating Organization..." />;
};

const AddZone: FC<{
  onComplete: (zone: Zone) => void;
}> = ({ onComplete }) => {
  const { currentUser } = useAuth();
  const tzDetails = currentUser!.timeZoneDetails;

  const zonesQuery = useQuery<ListZonesResponse>("zones", async () =>
    get("/zones")
  );

  useEffect(() => {
    // If we somehow arrived here with an existing zone, use that one and move on
    if (zonesQuery.data && zonesQuery.data.zones[0]) {
      onComplete(zonesQuery.data!.zones[0]);
      return;
    }

    // When the data comes in, if we have no zones, create a default one
    if (zonesQuery.data && zonesQuery.data.zones.length === 0) {
      const payload = {
        name: DEFAULT_ZONE_NAME,
        open: "00:00",
        timeZone: tzDetails.name,
      };

      post<CreateZoneResponse>("/zones", payload).then((response) =>
        onComplete(response.zone)
      );
    }
  }, [zonesQuery, onComplete, tzDetails]);

  return <LoadingScreen message="Creating Zone..." />;
};

const AddVehicle: FC<{
  zone: Zone;
  onComplete: () => void;
}> = ({ zone, onComplete }) => {
  const vehiclesQuery = useQuery<ListVehiclesResponse>("vehicles", async () =>
    get("/vehicles")
  );
  const hasVehicles = vehiclesQuery.data && vehiclesQuery.data.vehicles.length;
  const hasRunOnComplete = useRef(false);
  const vehicleCreationInFlight = useRef(false);
  const { accountDefaults } = useSignUpWizardStore();
  const defaultHomeLocation = accountDefaults?.homeLocation;

  // Avoid running onComplete multiple times which can happen with the useEffect
  // check for existing vehicles alongside the creation of the vehicle itself
  const handleComplete = useCallback(() => {
    if (!hasRunOnComplete.current) {
      hasRunOnComplete.current = true;
      onComplete();
    }
  }, [onComplete]);

  // If location defaults were provided via querystring during the signup process then
  // we will auto create the vehicle here using those values
  useEffect(() => {
    if (defaultHomeLocation && !vehicleCreationInFlight.current) {
      vehicleCreationInFlight.current = true;
      post<any, CreateVehicleRequest>("/vehicles", {
        name: DEFAULT_VEHICLE_NAME,
        home: defaultHomeLocation,
        open: "00:00",
        close: "23:59",
        capacity: { ft3: 0, lbs: 0 },
        zone,
      }).then(handleComplete);
    }
  }, [defaultHomeLocation, handleComplete, zone]);

  // If we somehow arrived here with an existing vehicle will consider this step complete
  useEffect(() => {
    if (hasVehicles) {
      handleComplete();
    }
  }, [hasVehicles, handleComplete]);

  if (vehiclesQuery.isLoading) {
    return <LoadingScreen message="Loading Vehicles..." />;
  }

  if (hasVehicles) {
    // Already have a vehicle. The effect above will take care of navigating.
    return <LoadingScreen message="Vehicles Loaded!" />;
  }

  if (defaultHomeLocation) {
    // Already have a home location. The effect above will take care of creating the vehicle.
    // We'll leave the screen loading while that occurs.
    return <LoadingScreen message="Received Vehicle Details..." />;
  }

  return (
    <WizardStep
      heading="Add a Vehicle"
      detail="Each Vehicle must begin and end a route from their Home location"
    >
      <VehicleForm
        onSubmitSuccess={handleComplete}
        defaultZone={zone}
        defaultName="Default Vehicle"
      />
    </WizardStep>
  );
};

export const Onboarding: FC = () => {
  const navigate = useNavigate();
  const { refetchCurrentUser } = useAuth();
  const [step, setStep] = useState(OnboardingStep.NAME_ORGANIZATION);
  const [zone, setZone] = useState<Zone>();
  const { getPendingInvite } = useInvitation();
  const inviteId = getPendingInvite();

  const finishOnboarding = useCallback(async () => {
    // User will now have an organization and will be allowed into the rest of the app
    const routesUser = await refetchCurrentUser();
    routesUser.activeOrganization.id;

    navigate("/");
  }, [navigate, refetchCurrentUser]);

  const content = useMemo(() => {
    // If the user came in via invite then render the invitation here instead of
    // onboarding, as we don't need them to create their own org, zone, vehicles
    if (inviteId) {
      return <InviteAcceptance id={inviteId} />;
    }

    switch (step) {
      case OnboardingStep.NAME_ORGANIZATION:
        return (
          <CreateOrganization
            onComplete={() => setStep(OnboardingStep.ADD_ZONE)}
          />
        );
      case OnboardingStep.ADD_ZONE:
        return (
          <AddZone
            onComplete={(zone) => {
              setZone(zone);
              setStep(OnboardingStep.ADD_VEHICLE);
            }}
          />
        );
      case OnboardingStep.ADD_VEHICLE:
        return <AddVehicle zone={zone!} onComplete={finishOnboarding} />;
    }
  }, [inviteId, finishOnboarding, step, zone]);

  return <Box sx={{ height: "100vh" }}>{content}</Box>;
};
