import { object, ObjectSchema, string } from "yup";
import { is24HrTime } from "../time";
import type { Zone } from "./zone";

export type UpdateZoneRequest = Partial<
  Omit<Zone, "id" | "optimizerJobInProgress">
>;

export const updateZoneSchema: ObjectSchema<UpdateZoneRequest> = object({
  name: string().required("Name is required"),
  timeZone: string().required("Time zone is required"),
  open: string()
    .required("Open time is required")
    .test("24hr-open-time", "Open time is not valid", (open) =>
      open === undefined ? true : is24HrTime(open)
    ), // With partial(), these tests are still run even on undefined values and after `required`
}).partial();
