import AddCircle from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { get } from "../lib/amplify";
import { ListAppointmentsResponse, Route, Stop } from "../shared/api_schema";
import { toLocale } from "../shared/frontend";
import { RButton } from "./RButton";
import { RDialog } from "./RDialog";
import { AddAppointmentForm } from "./forms/AddAppointmentForm";

const PAGE_SIZE = 10;

export const AddAppointment: FC<{ route: Route }> = ({ route }) => {
  const [showAppointmentSelect, setShowAppointmentSelect] = useState(false);
  const [appointment, setAppointment] = useState<Stop>();

  const appointmentsQuery = useQuery<ListAppointmentsResponse>(
    ["zone_appointments", route.zone.id],
    async () => get(`/appointments?zone=${route.zone.id}`)
  );

  const columns: GridColDef<Stop>[] = [
    {
      field: "name",
      headerName: "Name",
      valueGetter: ({ row }) => row.location.name,
      flex: 1,
    },
    {
      field: "open",
      headerName: "Open",
      valueGetter: ({ row }) => toLocale(row.open, row.location.timeZone),
      flex: 1,
    },
    {
      field: "close",
      headerName: "Close",
      valueGetter: ({ row }) => toLocale(row.close, row.location.timeZone),
      flex: 1,
    },
    {
      field: "add",
      headerName: "",
      renderCell: ({ row: stop }) => (
        <Button
          onClick={() => {
            setShowAppointmentSelect(false);
            setAppointment(stop);
          }}
        >
          Add
        </Button>
      ),
    },
  ];

  return (
    <>
      <RButton
        loading={appointmentsQuery.isLoading}
        disabled={!appointmentsQuery.data?.appointments}
        variant="contained"
        color="primary"
        onClick={() => setShowAppointmentSelect(true)}
        startIcon={<AddCircle />}
      >
        Add Appointment
      </RButton>
      <RDialog
        open={showAppointmentSelect}
        closeCallback={() => setShowAppointmentSelect(false)}
        title="Add Appointment"
      >
        <DataGrid
          rows={appointmentsQuery.data?.appointments ?? []}
          columns={columns}
          disableRowSelectionOnClick
          autoHeight
          pageSizeOptions={[PAGE_SIZE]}
          rowCount={appointmentsQuery.data?.appointments.length ?? 0}
        />
      </RDialog>
      {appointment && (
        <RDialog
          open
          title="Add Appointment"
          closeCallback={() => setAppointment(undefined)}
        >
          <AddAppointmentForm
            appointment={appointment}
            route={route}
            onSuccess={() => setAppointment(undefined)}
          />
        </RDialog>
      )}
    </>
  );
};
