import AddCircle from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { Page } from "../../components/Page";
import { PaginatedDataGrid } from "../../components/PaginatedDataGrid";
import { RDialog } from "../../components/RDialog";
import { FormTextField } from "../../components/forms/FormTextField";
import { get } from "../../lib/amplify";
import type { Customer, ListCustomersResponse } from "../../shared/api_schema";
import { CustomerForm } from "./CustomerForm";
import { ScheduledAppointments } from "./ScheduledAppointments";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    valueGetter: (p) => p.row.location.name,
  },
  {
    field: "address",
    headerName: "Address",
    flex: 1,
    valueGetter: (p) => p.row.location.address,
  },
  {
    field: "locality",
    headerName: "Locality",
    flex: 1,
    valueGetter: (p) => p.row.location.locality,
  },
  {
    field: "region",
    headerName: "Region",
    flex: 1,
    valueGetter: (p) => p.row.location.region,
  },
];

type SearchForm = { search: string };

export const Customers = () => {
  const [customerDialogOpen, setCustomerDialogOpen] = useState<boolean>(false);
  const [editingCustomer, setEditingCustomer] = useState<Customer>();
  const [tabIndex, setTabIndex] = useState(0);
  const form = useForm<SearchForm>({ defaultValues: { search: "" } });
  const [query, setQuery] = useState<string>();

  const searchQuery = useQuery<ListCustomersResponse>(
    ["customer", "search", query],
    async () => get(`/customers?query=${query}`),
    { enabled: !!query }
  );

  async function formSubmit(data: SearchForm) {
    console.log(data.search);
    setQuery(data.search);
  }

  return (
    <Page>
      <Grid container>
        <Grid item md={4}>
          <form
            noValidate
            autoComplete="off"
            onSubmit={form.handleSubmit(formSubmit)}
          >
            <FormTextField
              label="Search"
              name="search"
              control={form.control}
              errors={form.formState.errors}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ display: query ? "flex" : "none" }}
                  >
                    <IconButton
                      onClick={() => {
                        form.reset();
                        setQuery(undefined);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
          {query && (
            <Box>
              <Typography mt={4}>Searching for &quot;{query}&quot;</Typography>
              <Typography style={{ fontSize: 13 }}>
                (Results are limited to 100)
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item md={8}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={() => {
                setEditingCustomer(undefined);
                setCustomerDialogOpen(true);
              }}
              variant="contained"
              color="primary"
              startIcon={<AddCircle />}
            >
              Add Customer
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid paddingTop={4} item>
        {query ? (
          <DataGrid
            loading={searchQuery.isLoading}
            rows={searchQuery.data?.customers ?? []}
            columns={columns}
            autoHeight
          />
        ) : (
          <PaginatedDataGrid
            model="customers"
            columns={columns}
            onRowClick={(params) => {
              setEditingCustomer(params.row as Customer);
              setCustomerDialogOpen(true);
            }}
          />
        )}
      </Grid>

      <RDialog
        open={customerDialogOpen}
        title={`${editingCustomer ? "Edit" : "Add"} Customer`}
        closeCallback={() => setCustomerDialogOpen(false)}
        maxWidth={"lg"}
      >
        {editingCustomer && (
          <Tabs value={tabIndex} onChange={(_, index) => setTabIndex(index)}>
            <Tab label="Customer"></Tab>
            <Tab label="Scheduled Appointments"></Tab>
          </Tabs>
        )}

        <Box pt={4}>
          {tabIndex === 0 && (
            <CustomerForm
              customer={editingCustomer}
              onSubmitSuccess={() => setCustomerDialogOpen(false)}
            />
          )}
          {tabIndex === 1 && (
            <ScheduledAppointments customer={editingCustomer!} />
          )}
        </Box>
      </RDialog>
    </Page>
  );
};
