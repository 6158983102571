import { Box, Button, Grid, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Page } from "../../components/Page";
import { RButton } from "../../components/RButton";
import { SignOutButton } from "../../components/SignOutButton";
import { FormTextField } from "../../components/forms/FormTextField";
import { isDev, isLocal } from "../../config";
import { useUpdateUserMutation } from "../../hooks/mutations/useUpdateUserMutation";
import { useAuth } from "../../hooks/useAuth";
import { useSnackbar } from "../../hooks/useSnackbar";
import type { UpdateUserRequest } from "../../shared/api_schema";
import { userRoleString } from "../../shared/frontend";

export const Profile: FC = () => {
  const { currentUser, refetchCurrentUser, token } = useAuth();
  const snackbar = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateUserRequest>({
    defaultValues: {
      ...currentUser,
    },
  });

  const updateUserMutation = useUpdateUserMutation();

  function formSubmit(data: UpdateUserRequest) {
    updateUserMutation.mutate(
      {
        ...data,
        id: currentUser!.id,
        role: currentUser!.activeOrganization.role,
      },
      {
        onSuccess: () => {
          snackbar.show("Profile updated!");
          refetchCurrentUser();
        },
      }
    );
  }

  // Log out various helpful values in local/dev modes
  useEffect(() => {
    if (isLocal() || isDev()) {
      console.log("User");
      console.log(currentUser!.id);
      console.log("Active Organization");
      console.log(currentUser!.activeOrganization.id);
      console.log("ID Token");
      console.log(token);
    }
  }, [currentUser, token]);

  return (
    <Page>
      <Typography variant="h1">
        {userRoleString(currentUser!.activeOrganization.role)}
      </Typography>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h2" style={{ marginRight: "0.5em" }}>
          {currentUser!.email}
        </Typography>
      </div>
      <Box my={4} gap={4} display="flex">
        <Button variant="outlined" href="/change_password">
          Change Password
        </Button>
        <SignOutButton />
      </Box>
      <Box my={4}>
        <Typography variant="h2">Profile</Typography>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(formSubmit)}>
          <Box my={4}>
            <Grid container spacing={4} style={{ maxWidth: 600 }}>
              <Grid item xs={6}>
                <FormTextField
                  control={control}
                  errors={errors}
                  rules={{ required: "A first name is required" }}
                  name="firstName"
                  label="First Name"
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  control={control}
                  errors={errors}
                  rules={{ required: "A last name is required" }}
                  name="lastName"
                  label="Last Name"
                />
              </Grid>
            </Grid>
          </Box>
          <RButton loading={updateUserMutation.isLoading} type="submit">
            Save
          </RButton>
        </form>
      </Box>
    </Page>
  );
};
